import ky from "ky"

export const api = ky.extend({
  hooks: {
    afterResponse: [
      (_request, _options, response) => {
        // If we don't have JSON, then we have a 500 level error where no data
        // was returned. At that point, throw the response and let the generic
        // error handling kick in.
        if (!response.ok && !response.headers.get("content-type")?.includes("application/json")) {
          throw new Error(response.statusText)
        }

        return response
      },
    ],
  },
})
