import { ButtonAnchor, type ButtonAnchorProps } from "@/components/ui/button"
import { cn } from "@/lib/frontend/shadcn"
import type { ApiListJob } from "@/types"
import React, { useCallback } from "react"
import useApply from "@/lib/frontend/hooks/useApply"

export type AppcastApplyLinkProps = {
  job: ApiListJob
  uiPlacement: string
  onCaptureCallback?: () => void
  fullWidth?: boolean
  onClickTrackingParams?: Record<string, string | number | null | undefined | boolean>
  buttonText?: string
  disableCpaEmailPrompt?: boolean
} & Omit<ButtonAnchorProps, "href" | "onClick" | "target">

export const AppcastApplyLink: React.FC<AppcastApplyLinkProps> = ({
  job,
  uiPlacement,
  fullWidth,
  className,
  onClickTrackingParams,
  buttonText = "Apply",
  onCaptureCallback,
  disableCpaEmailPrompt = false,
  ...props
}) => {
  const {
    url,
    requireCapture: normallyRequiresCaptures,
    handleConversion,
    handleClickRequiringEmail,
  } = useApply(job, {
    trackingParams: { uiPlacement },
  })

  const requireCapture = !disableCpaEmailPrompt && normallyRequiresCaptures
  /*
   * Complex ApplyEvent [Click] that shows a modal with an capture email
   * Used for CPA jobs & Organic and is bound in `<AppCastApplyLink />`
   */

  const handleApplyEmailCapture = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()
      // Prevent the clicking of this button from triggering a click in the job card
      e.stopPropagation()
      handleClickRequiringEmail()
    },
    [handleClickRequiringEmail]
  )

  if (requireCapture) {
    return (
      <ButtonAnchor
        onClick={handleApplyEmailCapture}
        href={url.toString()}
        target="_blank"
        fullWidth={fullWidth}
        className={cn("px-3.5", className)}
        size="sm"
        {...props}
      >
        {buttonText}
      </ButtonAnchor>
    )
  }

  return (
    <ButtonAnchor
      onClick={(e) => {
        e.stopPropagation()
        handleConversion()
      }}
      href={url.toString()}
      target="_blank"
      fullWidth={fullWidth}
      className={cn("px-3.5", className)}
      size="sm"
      {...props}
    >
      {buttonText}
    </ButtonAnchor>
  )
}
