import { useStatsigClient } from "@statsig/react-bindings"
import { useMemo } from "react"
import { MapBounds } from "@/types"

// We export these hooks because previous versions of the Statsig SDK did not have centralized logging capabilities and
// required wrapper functions that this codebase has come to depend on. We are going to require imports from this
// module instead of the default for now.
export { useStatsigClient, useExperiment, useGateValue as useGate, useLayer } from "@statsig/react-bindings"

// Layer specific hooks!
export function useStoreFeedLayer() {
  // We are using the client directly to follow Statsig recommendations. Technically, exposures for a layer experiment
  // only happens on calling `.get`, unlike a `useExperiment`, which is triggered upon calling it, but we just want to
  // be consistent with the rest of the codebase.
  const client = useStatsigClient()

  return useMemo(
    () =>
      ({
        mapBounds: () => {
          const layer = client.getLayer("store_feed")
          return layer.get<MapBounds>("map_bounds", "viewport" as const) as MapBounds
        },
        tinyLittlePins: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("tiny_little_pins", false)
        },
        emailCaptureV2: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("email_capture_v2", false)
        },
        emailCaptureV2GoogleLoginEnabled: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("email_capture_v2_google_login_enabled", false)
        },
        // We are going to ship the WhatWhere header to desktop users while we
        // wait for the mobile what where header.
        whatWhereHeader: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("what_where_header", true)
        },
        trendingDropdown: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("trending_dropdown", false)
        },
        showAllPins: () => {
          const layer = client.getLayer("store_feed")
          return layer.get<"yes" | "no" | "cluster">("show_all_pins", "no")
        },
        whatWhereFilters: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("what_where_filters", false)
        },
        jobPage: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("job_page", false)
        },
        autocompleteSearch: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("autocomplete_search", false)
        },
        slugHeroDetail: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("slug_hero_detail_v1", false)
        },
        mobileWhatWhere: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("mobile_what_where", false)
        },
        defaultSortBy: () => {
          const layer = client.getLayer("store_feed")
          return layer.get<"cpa" | "distance" | "have_pay+cpa" | "score_v1" | "score_v2">("default_sort_by", "score_v2")
        },
        prefillWhereInput: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("prefill_where_input", false)
        },
        whatWherePlaceholders: () => {
          const layer = client.getLayer("store_feed")
          return [
            layer.get("what_where_header_what_placeholder", "search keywords"),
            layer.get("what_where_header_where_placeholder", "your address"),
          ]
        },
        hideJobAndEmployerCounts: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("hide_job_and_employer_counts", false)
        },
        lazyLoadMapOnMobile: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("lazy_load_map_on_mobile", true)
        },
        storeCardClickOpensFirstJob: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("store_card_click_opens_first_job", false)
        },
        storeCardFeedbackPrompt: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("store_card_feedback_prompt", false)
        },
        notifyButton: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("notify_button", false)
        },
        normalizedJobTitle: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("normalized_job_title", false)
        },
        zeroResultsPanel: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("zero_results_panel_using_slug_pages", false)
        },
        emailCaptureDismissals: () => {
          const layer = client.getLayer("store_feed")
          return Number(layer.get("email_capture_dismissals") ?? "0")
        },
        jobPageModal: () => {
          const layer = client.getLayer("store_feed")
          return layer.get("job_page_modal", false)
        },
      }) satisfies Record<string, () => any>,
    [client]
  )
}

export function useStoreCardLayer() {
  const client = useStatsigClient()

  return useMemo(
    () =>
      ({
        storeBadge: () => {
          const layer = client.getLayer("store_card")
          return layer.get("different_store_badge", "")
        },
        storeCardAiBadges: () => {
          const layer = client.getLayer("store_card")
          return layer.get("store_card_ai_badges", false)
        },
      }) satisfies Record<string, () => any>,
    [client]
  )
}

export function useMapLayer() {
  const client = useStatsigClient()

  return useMemo(
    () => ({
      googleMapsMapId: () => {
        const layer = client.getLayer("map")
        return layer.get("google_maps_map_id", "44b3568c76d9ce56")
      },
    }),
    [client]
  )
}
