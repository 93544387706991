import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { getEmployerName, getLogoUrl } from "@/lib/shared/gradientColors"
import { cn } from "@/lib/frontend/shadcn"

interface Props {
  employerTitle: string
  employerLogoUrl: string | undefined
  className?: string
}

export const EmployerLogo = ({ employerTitle, employerLogoUrl, className }: Props) => {
  const logoUrl = getLogoUrl(employerLogoUrl)
  const { employerNameFirstLetter, gradientColor } = getEmployerName(employerTitle)

  return (
    <Avatar className={cn("border-solid border-[1px] border-[#ced4da] bg-clip-box box-border block", className)}>
      <AvatarImage
        src={logoUrl}
        alt={employerTitle}
        width={40}
        height={40}
        decoding="async"
        loading="lazy"
        className={cn("object-fill object-center")}
      />
      <AvatarFallback className={cn(`w-full h-full flex items-center justify-center font-medium`, gradientColor)}>
        {employerNameFirstLetter}
      </AvatarFallback>
    </Avatar>
  )
}
