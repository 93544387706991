"use client"

import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { Dialog, DialogHeader, DialogTitle, DialogDescription, DialogContent } from "@/components/ui/dialog"
import { LabeledInput } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Text } from "@/components/ui/text"
import { Flex } from "@/components/ui/flex"
import { TermsOfServiceDisclaimer } from "./TermsOfServiceDisclaimer"
import useContactInformationCapture from "@/lib/frontend/hooks/useContactInformationCapture"
import { LoginWithGoogleButton } from "./LoginWithGoogleButton"
import { cn } from "@/lib/frontend/shadcn"
import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"

export type ContactInfoCaptureProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  cta?: React.ReactNode
  showForm?: boolean
  externalDialog?: boolean
  googleLoginEnabled?: boolean
  redirectUrl?: string
  useMantineStyle?: boolean
  onCapture?: (params: { method: "email" | "google"; consent_to_marketing: boolean; openLink?: boolean }) => void
  onOpen?: () => void
  onClose?: () => void
}

type ContactInfoData = {
  email: string
  consent_to_marketing: boolean
}

/**
 * Consumers for the `<ContactInfoCapture />` component will often pass in their own handling for tracking. When you
 * would like the tracking wired up for you automatically, use this component.
 */
export const TrackedContactInfoCapture: React.FC<ContactInfoCaptureProps> = ({ onCapture, onOpen, ...props }) => {
  const [, setTrackedOpen] = useState(false)
  const analytics = useAnalytics()
  // TODO The Mantine email modal kept beating the Tailwind one after multiple experiments. This should not be the case
  // but instead of fighting it, we are going to style the email modal exactly like the old one while we keep
  // experimenting.
  const { emailCaptureV2 } = useStoreFeedLayer()

  const handleOpen = useCallback(() => {
    analytics.track("shown_email_capture")
    onOpen?.()
  }, [analytics, onOpen])

  const handleCapture = useCallback<NonNullable<ContactInfoCaptureProps["onCapture"]>>(
    (params) => {
      analytics.track("shown_email_capture_provided_email", params)
      onCapture?.(params)
    },
    [analytics, onCapture]
  )

  useEffect(() => {
    setTrackedOpen((curr) => {
      if (!curr) {
        handleOpen()
      }
      return true
    })
  }, [handleOpen])

  return (
    <ContactInfoCapture onOpen={handleOpen} onCapture={handleCapture} useMantineStyle={!emailCaptureV2()} {...props} />
  )
}

export const ContactInfoCapture = ({
  title,
  description,
  cta,
  showForm = true,
  googleLoginEnabled = false,
  externalDialog = false,
  redirectUrl,
  useMantineStyle = false,
  onCapture,
  onOpen,
  onClose,
}: ContactInfoCaptureProps) => {
  const handleOpenChange = useCallback(
    (opened: boolean) => {
      if (opened) {
        onOpen?.()
      } else {
        onClose?.()
      }
    },
    [onOpen, onClose]
  )

  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    externalDialog ? (
      children
    ) : (
      <Dialog open onOpenChange={handleOpenChange}>
        {children}
      </Dialog>
    )

  return (
    <Wrapper>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        onClick={(e) => {
          // This dialog lives inside of a button which lives inside of a store card.
          // Clicking on it can cause the event to bubble outwards and trigger events
          // for the parents that they shouldn't. This fixes that.
          e.stopPropagation()
        }}
        className={cn("max-w-[27.5rem] max-sm:top-[33%]", useMantineStyle && "rounded p-4")}
      >
        <DialogHeader className={cn(useMantineStyle && !title && "[&>*]:hidden h-5")}>
          {title && <DialogTitle>{title}</DialogTitle>}
          {description && <DialogDescription className={cn("!mt-4")}>{description}</DialogDescription>}
        </DialogHeader>
        {showForm && (
          <Form
            onCapture={onCapture}
            googleLoginEnabled={googleLoginEnabled}
            redirectUrl={redirectUrl}
            cta={cta}
            useMantineStyle={useMantineStyle}
          />
        )}
      </DialogContent>
    </Wrapper>
  )
}

// This Form must be seperate from the component above as having it inside of
// the <Dialog /> caused a bunch of weirdness.
const Form: React.FC<Omit<ContactInfoCaptureProps, "externalDialog">> = ({
  cta = "Continue",
  onCapture,
  googleLoginEnabled,
  redirectUrl,
  useMantineStyle = false,
}) => {
  const form = useForm<ContactInfoData>({
    defaultValues: {
      email: "",
      consent_to_marketing: true,
    },
  })
  const { capture } = useContactInformationCapture({
    onError: () => form.setError("root", { message: "An error occurred. Please try again." }),
  })

  const handleSubmit = form.handleSubmit(async (data, e) => {
    if (e?.currentTarget?.checkValidity?.()) {
      return false
    }

    try {
      await capture(data)
    } catch (_e) {
      form.setError("root", { message: "An error occurred. Please try again." })
      return false
    }

    onCapture?.({ method: "email", consent_to_marketing: data.consent_to_marketing, openLink: !!e })
  })

  // When this form is being used to capture an email before sending the user to
  // a CPA/Organic job, we must use native form submission logic to open a new
  // tab to redirect the user to the job. Doing this in JS means that we run
  // into issues with popup blockers. This means, when provided a URL, the
  // form's validation logic will be 100% client side and the `capture` call
  // will happen in the background of the user going to the job.
  return (
    <form
      method="GET"
      action={redirectUrl}
      target={redirectUrl ? "_blank" : undefined}
      onSubmit={redirectUrl ? () => handleSubmit() : handleSubmit}
      id="contact-info-capture-form"
      className={cn("flex", "flex-col", "gap-0")}
    >
      <LabeledInput
        type="email"
        label={useMantineStyle ? "Enter your email" : undefined}
        placeholder="Your email"
        autoFocus
        data-autofocus
        data-testid="email-input"
        autoComplete="email"
        error={form.formState.errors.email?.message ?? form.formState.errors.root?.message}
        {...form.register("email", {
          required: true,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        })}
      />
      <Flex direction="col" align="center" className={cn("gap-2", "mt-4")}>
        <Button
          className={cn(useMantineStyle && "rounded")}
          type="submit"
          fullWidth
          disabled={!form.formState.isValid}
          loading={form.formState.isSubmitting}
        >
          {cta}
        </Button>
        {googleLoginEnabled && (
          <>
            <Text size="xs">or</Text>
            <LoginWithGoogleButton
              target={redirectUrl ? "_blank" : "_popup"}
              redirectTo={redirectUrl}
              onSuccess={() => onCapture?.({ method: "google", consent_to_marketing: true })}
              fullWidth
            />
          </>
        )}
        <TermsOfServiceDisclaimer className={cn("mt-2")} />
      </Flex>
    </form>
  )
}
