import { formatOutboundJobUrl, isRecentlyAdded } from "@/lib/util"
import { ApiListJob } from "@/types"
import { useMap } from "@/components/MapProvider"
import { useAnalytics } from "./useAnalytics"
import { useUser } from "@/components/UserContext"
import Cookies from "js-cookie"
import { fbTrack, trackGoogleConversion } from "@/components/workmaps/lib/tracking"
import { useCallback } from "react"
import useContactInformationCapture from "./useContactInformationCapture"
import { usePortal } from "@/components/PortalProvider"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { TrackedContactInfoCapture } from "@/components/ContactInfoCapture"
import { useLocalStorageValue } from "@react-hookz/web"

export default function useApply(
  job: ApiListJob,
  {
    onCapture,
    trackingParams = {},
  }: {
    onCapture?: () => void
    trackingParams?: Record<string, string>
  } = {}
) {
  const urlBuilder = formatOutboundJobUrl(job.id)
  const analytics = useAnalytics()
  const emailModalPortal = usePortal("email-modal")
  const { emailCaptureV2, emailCaptureV2GoogleLoginEnabled, emailCaptureDismissals } = useStoreFeedLayer()
  const { mapVisible } = useMap()
  urlBuilder.searchParams.set("map_visible", mapVisible ? "true" : "false")
  const url = urlBuilder.toString()

  // If we do not have an email from a user, they must provide one to view CPA
  // and Organic job postings. If the user has been identified by analytics.js,
  // use that as a valid identifier, as users that landed on the site via an
  // email link should not need to provide their email again.
  const user = useUser()
  const { email: localStorageEmail } = useContactInformationCapture()
  const email = user?.email || localStorageEmail || Cookies.get("ajs_user_id")

  const handleConversion = useCallback(() => {
    // Track to Segment (Mixpanel, Statsig, etc.)
    analytics.track("Clicked Link", {
      url,
      source: job.source,
      categories: job.employer_categories,
      pay_estimated: job.pay_estimated,
      badgeRecentlyAdded: isRecentlyAdded(job.job_posted_at),
      badgeUrgentlyHiring: job.urgent,
      ...trackingParams,
    })
    // Track conversion to Google Analytics
    trackGoogleConversion()
    onCapture?.()
  }, [
    analytics,
    url,
    job.source,
    job.employer_categories,
    job.pay_estimated,
    job.job_posted_at,
    job.urgent,
    trackingParams,
    onCapture,
  ])

  /**
   * When the user provides information to view CPA jobs, we must handle it
   * slightly differently.
   */
  const handleCapture = useCallback(
    ({
      openLink = true,
      ...params
    }: {
      method: "email" | "google"
      consent_to_marketing: boolean
      openLink?: boolean
    }) => {
      analytics.track("shown_email_capture_provided_email", {
        jobId: job.id,
        url,
        ...trackingParams,
        ...params,
      })
      fbTrack("Lead", { content_name: "JobDetail:Apply-Email-Capture" })
      if (params.method === "email") {
        // https://stackoverflow.com/a/70463940/471292
        if (openLink) {
          setTimeout(() => {
            window.open(url, "_blank")
          })
        }
      }
      handleConversion()
      emailModalPortal.close()
    },
    [analytics, handleConversion, job.id, url, trackingParams, emailModalPortal]
  )

  const { value: modalsDismissed, set: setModalsDismissed } = useLocalStorageValue("emailModalsDismissed", {
    defaultValue: 0,
  })

  const allowedDismissals = emailCaptureDismissals()
  const experimentDoesNotRequireCapture = allowedDismissals > 0 && (modalsDismissed ?? 0) > allowedDismissals

  const handleDismissed = useCallback(() => {
    setModalsDismissed((count) => (count ?? 0) + 1)
  }, [setModalsDismissed])

  const handleClickRequiringEmail = useCallback(() => {
    emailModalPortal.open(
      <TrackedContactInfoCapture
        title={emailCaptureV2() ? "Enter your email to apply" : undefined}
        redirectUrl={url}
        onCapture={handleCapture}
        onClose={() => {
          emailModalPortal.close()
          handleDismissed()
        }}
        googleLoginEnabled={emailCaptureV2GoogleLoginEnabled()}
      />
    )
  }, [emailCaptureV2, emailCaptureV2GoogleLoginEnabled, emailModalPortal, handleCapture, url, handleDismissed])

  return {
    url,
    requireCapture: !email && job.verified && !experimentDoesNotRequireCapture,
    handleClickRequiringEmail,
    handleConversion,
    handleCapture,
    handleDismissed,
  }
}
